<template>
  <div id="form-crud-paper">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    <div class="card border-0 shadow p-3">
      <form @submit.prevent="initSubmit">

        <div class="form-group">
          <label for="number">Uid *</label>
          <input class="form-control" id="uid" v-model="paper.uid" type="text">
          <div v-if="errorsForm.uid">
            <p class="form-error text-danger" v-for="(error, index) in errorsForm.uid.errors" :key="index">
              {{ error }}
            </p>
          </div>
        </div>

        <div class="form-group">
          <label for="number">{{ $t('common.name') }} *</label>
          <input class="form-control" id="number" v-model="paper.name" type="text">
          <div v-if="errorsForm.name">
            <p class="form-error text-danger" v-for="(error, index) in errorsForm.name.errors" :key="index">
              {{ error }}
            </p>
          </div>
        </div>

        <div class="form-group">
          <label>{{ $t('common.finishing') }}</label>

          <div class="ml-5" v-for="(finishing, index) in FinishingModule.finishes" :key="index">
            <input
                class="form-check-input"
                type="checkbox"
                :id="'finishing-' + index"
                :checked="(listFormFinishes) ? listFormFinishes.includes(finishing.id) : false"
                :value="finishing.id"
                @change="checkboxFinishes($event, finishing.id)"
            />

            <label :for="'finishing-' + index" class="ml-2">{{ finishing.name }}</label>
          </div>
        </div>

        <input type="submit" class="btn mt-4 mb-2 btn-success" :value="valueButtonSubmit" />
      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: {
    paper: Object,
    listFinishes: Array,
    id: Number,
    actionForm: String
  },
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      errorsForm: [],
      listFormFinishes: [],
    }
  },
  created: function() {
    this.$store.dispatch('fetchFinishes', {
      perPage: null,
      page: null
    })

    // Handle var list
    this.listFormFinishes = []
  },
  updated() {
    this.listFormFinishes = this.listFinishes
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- CHECKBOX ------------------*/
    checkboxFinishes(event, finishingId) {
      if(event.target.checked) {
        this.listFormFinishes.push(finishingId)
      } else {
        let indexFinishing = this.listFormFinishes.findIndex(array => array === finishingId)
        if(indexFinishing > -1) {
          this.listFormFinishes.splice(indexFinishing, 1)
        }
      }
    },
    /*----------------- SUBMIT ------------------*/
    initSubmit()
    {
      // handle var
      this.paper.finishes = this.listFormFinishes

      // If paper (create or update)
      if(this.actionForm === 'edit') {
        this.edit()
      } else {
        this.create()
      }
    },
    /**
     * Create paper (submit form)
     */
    create() {

      this.showLoader()

      this.$store.dispatch('createPaper', this.paper)
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Update paper (submit form)
     */
    edit() {
      this.showLoader()

      this.$store.dispatch('updatePaper', { id: this.paper.id, paper: this.paper })
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Redirect to paper-list after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh()
    {
      // Redirect
      this.$router.push({ name: 'paper-index' })

      // Refresh errors form
      this.errorsForm = []
    }
  },
  computed: {
    /**
     * Display value for button submit
     * @returns {string}
     */
    valueButtonSubmit() {
      let value = ''
      if(this.actionForm === 'edit')
      {
        value = this.$t('pages.paper.editPaper')
      }
      else {
        value = this.$t('pages.paper.createPaper')
      }

      return value
    },
    ...mapState(['PaperModule']),
    ...mapState(['FinishingModule']),
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
